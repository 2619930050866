import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import { HeroFeature } from "components/Hero";
import Grid, { Container } from "components/Grid";
import { ActionBlock } from "components/Block";

import { Article } from "./styles";

const DefaultPageTemplate = ({ page, markdown }) => {
  return (
    <>
      <HeroFeature content={page.hero} />
      <Grid>
        <Container mx="auto">
          <Article dangerouslySetInnerHTML={{ __html: markdown }} />
        </Container>
        <ActionBlock content={page.cta} />
      </Grid>
    </>
  );
};

// DefaultPageTemplate.propTypes = {
//   title: PropTypes.string
// };

const DefaultPage = ({ data }) => {
  const { frontmatter: page, html: markdown } = data.markdownRemark;
  return (
    <Layout shade="light" page={page}>
      <DefaultPageTemplate page={page} markdown={markdown} />
    </Layout>
  );
};

DefaultPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default DefaultPage;

export const defaultPageQuery = graphql`
  query DefaultPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        page {
          title
        }
        hero {
          heading
          subheading
          buttonText
          buttonPath
        }
        cta {
          heading
          subheading
          buttonText
          buttonPath
        }
      }
    }
  }
`;
