import styled from "styled-components";

import theme from "config/theme";

export const Article = styled.article`
  padding-bottom: 100px;
  color: ${theme.color.gray2};

  a {
    color: ${theme.color.gray};

    &:hover {
      color: ${theme.color.primary};
    }
  }

  h1,
  h2 {
    color: ${theme.color.gray};
  }

  table {
    width: 100%;
    margin: 40px auto;
    text-align: left;
    line-height: 1.5;
    border-radius: ${theme.radius};
    font-size: ${theme.font.size.zeta};
    border: 2px solid ${theme.color.paper2};
  }

  th {
    padding: 10px;
    line-height: 1.3;
    vertical-align: bottom;

    @media (max-width: ${theme.size.mobile}) {
      display: block;
    }
  }

  td {
    padding: 10px;
    vertical-align: top;
    border-top: 1px solid ${theme.color.paper2};

    @media (max-width: ${theme.size.mobile}) {
      display: block;
      border-left: 2px solid ${theme.color.paper2};
      border-right: 2px solid ${theme.color.paper2};

      &:first-child {
        border-top: 2px solid ${theme.color.paper2};
      }

      &:last-child {
        border-bottom: 2px solid ${theme.color.paper2};
      }
    }
  }
`;
